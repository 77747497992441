import { useUser, useAuth } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from './Page';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:8082';
export const CLERK_JWT_TEMPLATE = process.env.REACT_APP_CLERK_JWT_TEMPLATE || 'Bitloops';

export default function App() {
  const { isSignedIn, user } = useUser();

  const { getToken } = useAuth();

  const [searchParams] = useSearchParams();
  const [nonce, setNonce] = useState<string | null>(null);
  const [serverUrl, setServerUrl] = useState<string | null>(null);
  useEffect(() => {
    const nonce = searchParams.get('nonce');
    if (nonce) {
      setNonce(nonce);
      setServerUrl(SERVER_URL);
    }
  }, [searchParams]);

  useEffect(() => {
    console.log('Sign-in status changed', isSignedIn, user);
    if (isSignedIn && nonce) {
      // Send notification
      (async () => {
        const token = await getToken({ template: CLERK_JWT_TEMPLATE });
        console.log('Sending token to server', token);
        fetch(`${serverUrl}/auth/complete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nonce,
            user,
            token,
          }),
        }).catch((error) => {
          console.error(error);
        });
      })();
    }
  }, [isSignedIn, nonce]);

  return <Page />;
}

// Id nonce starts with 'dev' then it's development
const isDevelopment = (nonce: string | null): boolean => {
  if (!nonce) return false;
  return nonce.startsWith('dev');
};
