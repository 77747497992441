import Bitloops from './assets/Bitloops-Full-White-Background.svg';
import { Step } from './Step';
import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';

interface PageProps {}

export const Page = (props: PageProps) => {
  return (
    <main className="mt-8">
      <div className="flex flex-col items-center gap-10 mb-10">
        <div className="bg-white max-w-[1200px] w-[80%] p-8 shadow-2xl rounded-lg min-h-[600px]">
          <div className="flex flex-col items-center mb-10 mt-4">
            <img className="max-w-[300px] w-[80%]" src={Bitloops} alt="Bitloops" />
          </div>

          {/* If not signed in */}
          <SignedOut>
            <div className="ctas flex flex-wrap items-center gap-6 justify-center mt-14">
              <SignInButton>
                <div className={`primary_button github text-white rounded-xl`} role="button" tabIndex={0}>
                  <div className="text-white">{`Sign in`}</div>
                </div>
              </SignInButton>
            </div>
          </SignedOut>

          <SignedIn>
            <div className="flex flex-col gap-6 items-center">
              <h1 className="text-black text-center font-bold text-[2rem]">You have successfully logged in!</h1>
              <div className="checkmark">
                <div className="checkmark_circle"></div>
                <div className="checkmark_stem"></div>
                <div className="checkmark_kick"></div>
              </div>
              <h2 className="text-black text-center font-bold text-[1.5rem]">
                To start converting designs into code, follow these steps:
              </h2>
              <div className="mx-auto my-6" style={{ maxWidth: '100%', padding: '0 1rem' }}>
                <ul
                  className="text-black text-lg grid gap-8 text-center align-items-start"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(483px, 1fr))',
                    justifyContent: 'center',
                  }}
                >
                  <Step title={'1. Create Project'} image={'/assets/images/create-project.png'}>
                    Create & name your Bitloops Project in the extension
                  </Step>
                  <Step title={'2. Import Design'} image={'/assets/images/import-design.png'}>
                    Import the Figma design by simply pasting the design URL
                  </Step>
                  <Step title={'3. Add Frames'} image={'/assets/images/add-frame.png'}>
                    Select the frame(s) to convert and add to the Project
                  </Step>
                  <Step title={'4. Create Component'} image={'/assets/images/create-component.png'}>
                    Select the parent container and click "Create Component"!
                  </Step>
                </ul>
                <p className="mt-3"></p>
              </div>

              <p className="text-black text-center text-[1.5rem]">It's that simple! The Component will be created as well as its Storybook.</p>
              <p className="text-black text-center text-[1.5rem]">Save days of work with Bitloops!</p>
              <p className="text-black text-center text-[1.2rem]">You can close this window at any time.</p>
              <UserButton />
            </div>
          </SignedIn>
        </div>
      </div>
    </main>
  );
};
